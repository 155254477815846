import React, { useEffect, useState } from "react";
import CustomHeader from "../components/Header.js";
import CustomFooter from "../components/Footer.js";
import "../style/home.css";
import { Button, Carousel, Layout } from "antd";
import KeepInTouch from "../components/KeepInTouch.js";
import { useNavigate } from "react-router-dom";
import SubMenu from "../components/SubMenu.js";
import { useDispatch, useSelector } from "react-redux";
import { setselectedKey } from "../slices/mapView.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import {
  faMagnifyingGlassChart,
  faSunPlantWilt,
} from "@fortawesome/free-solid-svg-icons";
const { Header, Footer, Content } = Layout;

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  background: "linear-gradient(180deg, #2a547c, #224669)",
  // zIndex: 5000,
};
const contentStyle = {
  color: "#fff",
};
const footerStyle = {
  padding: 0,
  margin: 0,
  // color: "#fff",
  backgroundColor: "#7dbcea",
  background: "linear-gradient(180deg, #2a547c 0%, #224669 100%)",
};

const Home = (props) => {
  const { darkmode } = useSelector((state) => state);
  const [isDarkMode, setIsDarkMode] = useState(() => darkmode);
  const [color, setcolor] = useState("#000");

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    setIsDarkMode(darkmode);
    setcolor(!darkmode ? "#fff" : "#000");
    document.documentElement.style.setProperty(
      "--ll-color",
      !darkmode ? "#000" : "#fff"
    );
    document.documentElement.style.setProperty(
      "--ll-background-color",
      darkmode ? "#000" : "#fff"
    );
  }, [darkmode]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Layout>
        <Header style={headerStyle}>
          <CustomHeader heading={props.heading} />
        </Header>
        <Content style={contentStyle}>
          <SubMenu />
          <Carousel autoplay>
            <img
              src="images/Agri_Field_Pictures/1.jpg"
              height={300}
              alt="banner1"
            />
            <img
              src="images/Agri_Field_Pictures/2.jpg"
              height={300}
              alt="banner2"
            />
            <img
              src="images/Agri_Field_Pictures/3.jpg"
              height={300}
              alt="banner3"
            />
            <img
              src="images/Agri_Field_Pictures/4.jpg"
              height={300}
              alt="banner4"
            />
          </Carousel>
          <div id="content" className="container-fluid text-center">
            <div className="no-gutters row-padding row">
              <center lg="12">
                <h2 className="about-us">About Us</h2>
              </center>
              <div>
                <p className="select-text">
                  The Pakistan Drought Monitoring System (PAKDMS) portal is
                  developed as a part of the International Water Management
                  Institute's drought programme with the assistance of Pakistan
                  Meteorological Department to support the various stakeholders
                  in the Pakistan. The tool is developed specifically for
                  drought risk mitigation and enables timely action to be taken
                  by the government authorities and relevant development
                  organizations. It has several sub-modules to understand the
                  drought propagations, conditions to adaptation...
                  <span>
                    <Button
                      type="link"
                      href="aboutus"
                      className="readmore-text"
                      onClick={() => dispatch(setselectedKey("about"))}
                    >
                      Read More
                    </Button>
                  </span>
                </p>
              </div>
              <div
                className="card-column col-6 col-sm-6 col-lg-6"
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="custom-card-x cb-2 cursor-ponter"
                  style={{ height: "auto" }}
                >
                  <div className="landing-page-card">
                    <FontAwesomeIcon
                      icon={faSunPlantWilt}
                      size="4x"
                      color={darkmode ? "white" : "black"}
                    />
                    <p className="icon-text">Drought Monitoring</p>
                    <p
                      className="icon-small-text"
                      style={{ color: darkmode ? "white" : "#363636" }}
                    >
                      It allows users to monitor past or current drought
                      frequency, severity & help determine the conditions to
                      promote proactive drought management measures.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="card-column col-6 col-sm-6 col-lg-6"
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="custom-card-x cb-7 cursor-ponter"
                  style={{ height: "auto" }}
                >
                  <div className="landing-page-card">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlassChart}
                      size="4x"
                      color={darkmode ? "white" : "black"}
                    />
                    <p className="icon-text">Drought Prediction</p>
                    <p
                      className="icon-small-text"
                      style={{ color: darkmode ? "white" : "#363636" }}
                    >
                      Drought decision support tool offers triggers using
                      pre-defined conditions for drought alert to determine
                      drought conditions and support in contingency plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <KeepInTouch />
        </Content>
        <Footer style={footerStyle}>
          <CustomFooter />
        </Footer>
      </Layout>
    </>
  );
};

export default Home;
