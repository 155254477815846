import Axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import L from "leaflet";

import { useSelector } from "react-redux";

function VectorData(props) {
  const { darkmode, admin1, admin2 } = useSelector((state) => state);
  const map = useMap();

  const [data, setData] = useState([]);
  const [filtereddata, setFilteredData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [tehsils, setTehsils] = useState([]);

  const zoomToLayer = useCallback(
    (asd) => {
      setTimeout(() => {
        map.flyToBounds(L.geoJSON(asd).getBounds());
      }, 10);
    },
    [map]
  );

  const {
    adminUnit,
    selectedUnit,
    selectedDistrict,
    selectedTehsil,
    getdistricts,
    gettehsils,
  } = props;

  useEffect(() => {
    if (adminUnit) {
      let baseUrl =
        "../geoserver/PakDMS/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=PakDMS%3A" +
        adminUnit +
        "&outputFormat=application%2Fjson";
      if (adminUnit === "districts")
        baseUrl += "&CQL_FILTER=unit='" + selectedUnit + "'";
      if (adminUnit === "tehsils")
        baseUrl += "&CQL_FILTER=district='" + selectedDistrict + "'";
      if (adminUnit === "subtehsil")
        baseUrl =
          "../geoserver/PakDMS/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=PakDMS%3Atehsils&outputFormat=application%2Fjson&CQL_FILTER=district='" +
          selectedDistrict +
          "' AND name='" +
          selectedTehsil +
          "'";
      Axios.get(baseUrl).then((resp) => {
        if (adminUnit === "districts") {
          setData(resp.data.features);
          setFilteredData(
            data.filter((feature) => {
              if (feature.properties.name === selectedDistrict) return feature;
              else return null;
            })
          );
          setDistricts(resp.data.features);
          getdistricts(resp.data.features);
        }
        if (adminUnit === "tehsils") {
          setData(resp.data.features);
          setFilteredData(
            data.filter((feature) => {
              if (feature.properties.name === selectedTehsil) return feature;
              else return null;
            })
          );
          setTehsils(resp.data.features);
          gettehsils(resp.data.features);
        }
        if (adminUnit === "subtehsil") {
          setData(resp.data.features);
          setFilteredData(
            data.filter((feature) => {
              if (feature.properties.name === selectedTehsil) return feature;
              else return null;
            })
          );
          setTehsils(resp.data.features);
        }
        if (selectedDistrict == null || selectedTehsil == null)
          setFilteredData(resp.data.features);
        // setData(resp.data.features);
        if (!admin1) zoomToLayer(resp.data.features);
      });
    }
  }, [adminUnit, selectedDistrict, selectedUnit, selectedTehsil]);

  function getStyle() {
    return {
      fillColor: "#b2b2b2",
      weight: 1.5,
      color: darkmode ? "yellow" : "red",
      dashArray: "3",
      fillOpacity: 0.2,
    };
  }

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  function highlightFeature(e) {
    var layer = e.target;

    layer.setStyle({
      weight: 2,
      dashArray: "3",
      fillOpacity: 0.1,
      fillColor: darkmode ? "yellow" : "red",
    });

    layer.bringToFront();
  }

  function resetHighlight(e) {
    var layer = e.target;
    layer.setStyle({
      fillColor: "#b2b2b2",
      weight: 1.5,
      color: darkmode ? "yellow" : "red",
      dashArray: "3",
      fillOpacity: 0.2,
    });
  }

  const handleclick = (e) => {
    if (admin1) return;
    // setselectedDistrict(e.target.feature.properties.name);
    setFilteredData(
      data.filter((feature) => {
        if (feature.properties.name === e.target.feature.properties.name)
          return feature;
        else return null;
      })
    );
    let level,
      unit = "";
    // if (e.target.feature.id.indexOf("national") !== -1) {
    //   level = 0;
    //   unit = "national";
    // }
    if (e.target.feature.id.indexOf("unit") !== -1) {
      level = 1;
      unit = e.target.feature.properties.name;
    }
    if (e.target.feature.id.indexOf("district") !== -1) {
      level = 2;
      unit = e.target.feature.properties.unit;
    }
    if (e.target.feature.id.indexOf("tehsil") !== -1) {
      level = 3;
      unit = e.target.feature.properties.district;
    }
    props.clickedUnit({
      level: level,
      unit: unit,
      name: e.target.feature.properties.name,
      district: districts,
      tehsil: tehsils,
    });
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: handleclick,
    });

    layer
      .bindTooltip(feature.properties.name, {
        permanent: false,
        direction: "center",
      })
      .openTooltip();
  };

  if (data) {
    return (
      <GeoJSON
        key={generateKey("gjson")}
        style={getStyle}
        data={filtereddata}
        onEachFeature={onEachFeature}
      />
    );
  } else {
    return "";
  }
}
export default VectorData;
