import {
  LayerGroup,
  LayersControl,
  MapContainer,
  TileLayer,
} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import HeaderCustom from "../components/Header.js";
import SubMenu from "../components/SubMenu.js";
import {
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Flex,
  InputNumber,
  Layout,
  List,
  Modal,
  Select,
  Slider,
  Space,
  Spin,
  Tour,
  notification,
} from "antd";
import dayjs from "dayjs";

import "leaflet-loading";
import "leaflet-loading/src/Control.Loading.css";

import { Content, Header } from "antd/es/layout/layout";
import "../style/map.css";
import "../style/custom.css";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Control from "react-leaflet-custom-control";
import VectorData from "../utils/VectorData.js";
import "../style/sidebar.css";
import Sider from "antd/es/layout/Sider";
import {
  setLoggedIn,
  setSelected,
  setselectedKey,
  setshowTour,
} from "../slices/mapView.js";
import EarthEngine from "../utils/EarthEngine.js";
import Graph from "../utils/Graph.js";
import JRCLayer from "../utils/JRCLayer.js";
import { Box } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faCalculator,
  faCalendar,
  faChartLine,
  faCircleQuestion,
  faEye,
  faEyeSlash,
  faGrip,
  faLayerGroup,
  faListCheck,
  faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";
import AddMask from "../utils/AddMask.js";
import { useCallback } from "react";

const dateFormat = "YYYY-MM-DD";

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  background: "linear-gradient(180deg, #2a547c, #224669)",
  zIndex: 15000,
};
const contentStyle = {
  color: "#fff",
};

function MapWrapper(props) {
  const { Panel } = Collapse;

  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  // const [openTour, setopenTour] = useState(true);
  const steps = [
    {
      title: "PakDMS Map panel",
      description: "Click on polygon to select boundaries interactively",
      target: () => ref0.current,
    },
    {
      title: "Select Module",
      description: "Use this to switch between modules",
      target: () => ref4.current,
    },
    {
      title: "Select Boundary",
      description:
        "Select an administrative boundary from dropdown or by clicking on the map",
      target: () => ref5.current,
    },
    {
      title: "Select Indices",
      description:
        "Click to expand, select list of indices & read their information",
      target: () => ref6.current,
    },
    {
      title: "Select Start Date",
      description: "Use datepicker to select the start date for simulation",
      target: () => ref1.current,
    },
    {
      title: "Select End Date",
      description: "Use datepicker to select the end date for simulation",
      target: () => ref2.current,
    },
    {
      title: "Select Aggregation",
      description: "Use dropdown to select the aggregation parameter",
      target: () => ref3.current,
    },
    {
      title: "Actions",
      description: "Preview the layer for selected filters or view it's graph",
      target: () => ref7.current,
    },
  ];

  const data = [
    {
      title: "CWD",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>Crop Water Deficit (CWD) is a critical measure used in agriculture to describe the difference between the water demand of a crop and the actual water available to it through precipitation or irrigation.</p><p>CWD=PET - ET</p><p><em><strong>Refrences:</strong> Singh, M., Singh, P., Singh, S. et al. A global meta-analysis of yield and water productivity responses of vegetables to deficit irrigation. Sci Rep 11, 22095 (2021). https://doi.org/10.1038/s41598-021-01433-w</em></p></div>',
    },
    {
      title: "DrySpell",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>A dry spell is defined as the number of consecutive days with a daily precipitation amount below a certain threshold, such as 0.1, 1, 5, 10 mm, preceded and followed by at least one day with rainfall exceeding the threshold. The app uses rainfall product from CHIRPS to calculate the dry spell for specific time period ranging from few days to months.</p><p><em><strong>Reference:</strong> Suppiah, R. and Hennessy, K.J., 1998. Trends in total rainfall, heavy rain events and number of dry days in Australia, 1910-1990. International Journal of Climatology: A Journal of the Royal Meteorological Society, 18(10), pp.1141-1164.</em></p></div>',
    },
    {
      title: "MAI",
      disabled: false,
      detail:
        '<div className="search-content-img discription-text"><p>MAI stands for Moisture Adequacy Index.<br>Moisture Adequacy Index (MAI) is the ratio of actual evapotranspiration (AET) to the potential evapotranspiration (PET). Thus, MAI represents moisture effectivity which has impact on vegetation in relation to the climate. The range of MAI values varies between 0-1; value near to 0 reveals extreme stress while 1 expresses ample amount of soil moisture availability. &nbsp;&nbsp;<br>MAI = AET/PET</p><p><em><strong>Reference:</strong> Thornthwaite, C.W. and Mather, J.R., 1955. The water balance publications in Climatology, 8 (1). DIT, Laboratory of climatology, Centerton, NJ, USA.</em></p></div>',
    },
    {
      title: "NDVI",
      disabled: false,
      detail:
        "<p>NDVI stands for Normalize Difference Vegetation Index.<br>Although there are, several vegetation indices, one of the most widely used is the Normalized Difference Vegetation Index (NDVI). NDVI values range from +1.0 to -1.0. Areas of barren rock, sand, or snow usually show very low NDVI values (for example, 0.1 or less). Sparse vegetation such as shrubs and grasslands or senescing crops may result in moderate NDVI values (approximately 0.2 to 0.5).<br>NDVI = (NIR-RED)/(NIR+RED)<br>Where NIR = near infrared band, RED = red band<br><br><em><strong>Reference:</strong> Rouse Jr, J.W., Haas, R.H., Schell, J.A. and Deering, D.W., 1973. Monitoring the vernal advancement and retrogradation (green wave effect) of natural vegetation.</em></p>",
    },
    // {
    //   title: "NDWI",
    //   disabled: true,
    //   detail:
    //     '<div class="search-content-img discription-text"><p>NDWI stands for Normalize Difference Water Index.<br>The normalized difference water index can be utilized for evaluating vegetation liquid water contents or water inundated areas (Gao, 1996). NDWI is useful for evaluating reflectance from vegetation canopies that have similar scattering properties, but slightly different liquid water absorption due to canopy water content. As a result, NDWI is sensitive to changes in liquid water content of vegetation canopies and open water areas. The common range of NDWI for green vegetation is -0.1 to 0.4&nbsp;<br>There are different way of NDWI calculation as follows</p><p>NDWI = (GREEN-NIR)/(GREEN+NIR)<br>Where NIR = near infrared band, GREEN = green band</p><p><em><strong>Reference: </strong>Gao, B.C., 1996. NDWI - A normalized difference water index for remote sensing of vegetation liquid water from space. Remote Sensing of Environment, 58, 257–266.</em></p></div>',
    // },
    {
      title: "PCI",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>The Precipitation Condition Index (PCI) is a tool used in drought monitoring to directly respond to precipitation anomalies, making it essential for assessing agricultural drought.</p><p>PCI = [Precipitation(i) - Precipitation(min)]/[Precipitation(max) - Precipitation(min)]</p><p><em><strong>Refrences:</strong> Zhao, Y.; Zhang, J.; Bai, Y.; Zhang, S.; Yang, S.; Henchiri, M.; Seka, A.M.; Nanzad, L. Drought Monitoring and Performance Evaluation Based on Machine Learning Fusion of Multi-Source Remote Sensing Drought Factors. Remote Sens. 2022, 14, 6398. https://doi.org/10.3390/rs14246398</em></p></div>',
    },
    {
      title: "SMCI",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>The Soil Moisture Condition Index (SMCI) is a drought index that quantifies soil moisture to monitor agricultural drought conditions. It is derived from soil moisture and precipitation data, offering a direct measure of the soils wetness or dryness. This index is crucial for understanding and managing the impact of drought on agriculture by providing insights into the current state of soil moisture, which is vital for crop growth and productivity. </p><p>SMCI = [Soil_Moisture(i) - Soil_Moisture(min)]/[Soil_Moisture(max) - Soil_Moisture(min)]</p><p><em><strong>Refrences:</strong> Sánchez, N.; González-Zamora, Á.; Piles, M.; Martínez-Fernández, J. A New Soil Moisture Agricultural Drought Index (SMADI) Integrating MODIS and SMOS Products: A Case of Study over the Iberian Peninsula. Remote Sens. 2016, 8, 287. https://doi.org/10.3390/rs8040287</em></p></div>',
    },
    {
      title: "SPI_CHIRPS",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>The Standardized Precipitation Index (SPI) is a metric used globally to identify and measure drought intensity by analyzing precipitation deficits over multiple time scales. Developed in 1993 by McKee, Doesken, and Kleist, the SPI can assess conditions ranging from wet to dry, providing a standardized approach to compare drought severity</p><p><em><strong>Refrences:</strong> Mckee, T. B. and Nolan J. Doesken. “The Standardized Precipitation Index – an overview.” (2016).</em></p></div>',
    },
    {
      title: "SPI_ERA5L",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>The Standardized Precipitation Index (SPI) is a metric used globally to identify and measure drought intensity by analyzing precipitation deficits over multiple time scales. Developed in 1993 by McKee, Doesken, and Kleist, the SPI can assess conditions ranging from wet to dry, providing a standardized approach to compare drought severity</p><p><em><strong>Refrences:</strong> Mckee, T. B. and Nolan J. Doesken. “The Standardized Precipitation Index – an overview.” (2016).</em></p></div>',
    },
    {
      title: "TCI",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>The Temperature Condition Index (TCI) is a remote sensing-based index used to assess vegetation thermal stress due to variations in temperature, including excessive heat or wetness. It utilizes thermal bands from satellite data to estimate the stress on vegetation by comparing current land surface temperature conditions against historical temperature data ranges.</p><p>TCI = [LST(max) - LST(i)]/[LST(max) - LST(min)]</p><p><em><strong>Refrences:</strong> Niranga Alahacoon & Mahesh Edirisinghe (2022) A comprehensive assessment of remote sensing and traditional based drought monitoring indices at global and regional scale, Geomatics, Natural Hazards and Risk, 13:1, 762-799, DOI: 10.1080/19475705.2022.2044394</em></p></div>',
    },
    {
      title: "VCI",
      disabled: false,
      detail:
        '<div className="search-content-img discription-text"><p>VCI stands for Vegetation Condition Index.<br>Pixel-based VCI calculation is more effective to identify the drought condition irrespective of the ecological region. The range of VCI values varies between 0-100 and the value 0 reveals extreme stress while 100 expresses healthy vegetation. NDVImin and NDVImax are the long-term minimum and maximum NDVI for given pixel and the NDVIi is the current NDVI for the same pixel.&nbsp;<br>VCI = NDVIi -NDVIminNDVImax-NDVImin*100</p><p><em><strong>Reference:</strong> Kogan F.N. 1995. Application of vegetation index and brightness temperature for drought detection. Advances in Space Research, 15(11): 91–100. DOI: 10.1016/0273-1177(95)00079-T.</em></p></div>',
    },
    {
      title: "VHI",
      disabled: false,
      detail:
        '<div class="search-content-img discription-text"><p>The Vegetation Health Index (VHI) integrates the Vegetation Condition Index (VCI) and the Temperature Condition Index (TCI) to provide a comprehensive assessment of vegetation health, which is crucial for monitoring drought, assessing agricultural productivity, and managing food security. The VHI is instrumental in identifying areas with potential vegetation stress or favorable growth conditions, thus supporting decision-making in agriculture and environmental management.</p><p><em><strong>References:</strong> Zeng, J., Zhou, T., Qu, Y. et al. An improved global vegetation health index dataset in detecting vegetation drought. Sci Data 10, 338 (2023). https://doi.org/10.1038/s41597-023-02255-3</em></p></div>',
    },
  ];

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, msg, placement) => {
    api[type]({
      message: title,
      description: msg,
      placement,
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    darkmode,
    selected,
    center,
    zoom,
    module,
    showTour,
    admin1,
    admin1Name,
    admin2,
    admin2Name,
  } = useSelector((state) => state);

  const [opac, setOpac] = useState(1);
  const [showOpacity, setshowOpacity] = useState(false);
  const [modalIndice, setmodalIndice] = useState("");
  const [modalMessage, setmodalMessage] = useState("");
  const [modalopen, setmodalopen] = useState(false);
  // const [infoclicked, setinfoclicked] = useState(false);
  const [color, setcolor] = useState("#000");
  const [open, setOpen] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [reset, setreset] = useState(false);
  const [applyBtn, setapplyBtn] = useState(true);
  const [graphBtn, setgraphBtn] = useState(false);
  const [showGraph, setshowGraph] = useState(false);
  const [loadlayer, setloadlayer] = useState(false);
  const [legend, setlegend] = useState(false);
  const [showprecipitation, setshowprecipitation] = useState(false);
  const [showmonths, setshowmonths] = useState(false);
  const [months, setmonths] = useState(3);
  const [precipitation, setprecipitation] = useState(2.5);
  const [layermin, setlayermin] = useState(null);
  const [layermax, setlayermax] = useState(null);
  const [calctype, setcalctype] = useState(null);
  const [layerPalette, setlayerPalette] = useState(null);
  const [tehsils, setTehsils] = useState([]);
  const [calcval, setcalcval] = useState("mean");
  const [startmonth, setstartmonth] = useState(1);
  const [endmonth, setendmonth] = useState(dayjs().month() + 1);
  const [districts, setDistricts] = useState([]);
  const [selectedUnit, setselectedUnit] = useState(null);
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [selectedTehsil, setselectedTehsil] = useState(null);
  // const [level, setLevel] = useState(0);
  const [unit, setUnit] = useState("units");
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [loggedin, setloggedin] = useState(false);
  const [startYear, setstartYear] = useState(dayjs().year());
  const [enddefaultDate, setenddefaultDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs("2024-01-01"));

  const handleStartChange = (date) => {
    if (date) {
      const newStartYear = date.year();
      setstartYear(newStartYear);
      setStartDate(date);

      // Adjust the end date to match the new start year if necessary
      let newEndDate = date.year(newStartYear).month(11); // Set to December of the new start year
      const today = dayjs();

      if (newEndDate > today.endOf("month")) {
        newEndDate = dayjs().year(newStartYear).month(today.month());
      }

      setenddefaultDate(newEndDate);
    }
  };

  const handleIndice = (e) => {
    setloadlayer(false);
    setshowOpacity(false);
    setshowGraph(false);
    setshowprecipitation(false);
    setshowmonths(false);
    if (e === selected) {
      setshowprecipitation(false);
      setshowmonths(false);
      setapplyBtn(true);
      setgraphBtn(false);
      setlegend(false);
      dispatch(setSelected(null));
    } else {
      if (e === "SPI_CHIRPS" || e === "SPI_ERA5L") {
        setshowmonths(true);
        openNotificationWithIcon(
          "info",
          "Adjust months value if required",
          "",
          "bottomRight"
        );
      }
      if (e === "extreme_rainfall" || e === "DrySpell") {
        setshowprecipitation(true);
        openNotificationWithIcon(
          "info",
          "Adjust precipitation threshold value",
          "",
          "bottomRight"
        );
      }
      setlegend(false);
      setgraphBtn(e === "DrySpell" ? false : true);
      setapplyBtn(false);
      dispatch(setSelected(e));
    }
  };

  // const currentYear = dayjs.year();
  const minDate = dayjs("2001-01-01"); // January 1st, 2001

  const onClose = () => {
    setshowGraph(false);
  };

  const getdistricts = (e) => {
    let temp = [];
    e.forEach((feat) => {
      temp.push({ value: feat.properties.name, label: feat.properties.name });
    });
    temp.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    setDistricts(temp);
  };

  const gettehsils = (e) => {
    let temp = [];
    e.forEach((feat) => {
      temp.push({ value: feat.properties.name, label: feat.properties.name });
    });
    temp.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    setTehsils(temp);
  };

  const clickedUnit = (e) => {
    dispatch(setshowTour(false));
    setloadlayer(false);
    setshowOpacity(false);
    setreset(true);
    if (e.level === 1) {
      setUnit("districts");
      setselectedUnit(e.name);
    }
    if (e.level === 2) {
      setUnit("tehsils");
      setselectedDistrict(e.name);
    }
    if (e.level === 3) {
      setUnit("subtehsil");
      setselectedTehsil(e.name);
    }
  };

  useEffect(() => {
    dispatch(setSelected(null));
    setcolor(!darkmode ? "#fff" : "#000");
    document.documentElement.style.setProperty(
      "--ll-color",
      !darkmode ? "#000" : "#fff"
    );
    document.documentElement.style.setProperty(
      "--ll-background-color",
      darkmode ? "#000" : "#fff"
    );
    Axios.get("../backend/logincheck", {
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth) {
        setloggedin(true);
        navigate("/map");
        setLoading(false);
        dispatch(setLoggedIn(true));
      } else {
        dispatch(setLoggedIn(false));
        // dispatch(clearResults("reset"));
        // localStorage.removeItem("token");
        dispatch(setselectedKey(null));
        setLoading(true);
        setloggedin(false);
        navigate("/login");
      }
    });
  }, [darkmode, dispatch, module, navigate]);

  useEffect(() => {
    if (admin1 && admin1Name) setselectedUnit(admin1Name);
  }, []);

  const sliderChange = useCallback((e) => {
    setOpac(e);
  }, []);

  return (
    <>
      {contextHolder}
      <Spin
        indicator={
          <Box sx={{ width: "100%" }}>
            <img
              alt="revolving globe"
              src="https://media.tenor.com/4NKYe36DcE8AAAAj/taclan-world.gif"
              style={{ marginLeft: 40, width: 75, height: 75 }}
              spin="true"
            />
            <img
              alt="loading dots"
              src="https://media.tenor.com/mT5Timqns1sAAAAi/loading-dots-bouncing-dots.gif"
              style={{ width: 150, height: 75 }}
              spin="true"
            />
          </Box>
        }
        // fullscreen="true"
        spinning={loading}
      >
        <Layout style={{ padding: "0", margin: "0", height: "100vh" }}>
          <Header style={headerStyle}>
            <HeaderCustom heading={props.heading} loggedin={loggedin} />
          </Header>
          <SubMenu />
          <Layout hasSider>
            <Sider
              trigger={null}
              collapsedWidth="0"
              width={250}
              style={{
                textAlign: "center",
                color: "#fff",
                overflowY: "scroll",
                background: color,
                borderRight: "1px solid black",
              }}
              collapsible
              collapsed={collapsed}
              // onCollapse={(value) => setCollapsed(value)}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <div ref={ref1}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faCalendar} />
                    &nbsp;&nbsp;&nbsp;&nbsp; Start Date
                  </p>
                  <DatePicker
                    picker="month"
                    allowClear={false}
                    defaultValue={startDate}
                    disabledDate={(current) => {
                      const today = dayjs();
                      return (
                        (current && current < minDate) ||
                        (current && current > today.endOf("month"))
                      );
                    }}
                    format="MMM YYYY"
                    onChange={(e) => {
                      handleStartChange(e);
                      setrefresh(false);
                      setloadlayer(false);
                      setshowOpacity(false);
                      setshowGraph(false);

                      setstartmonth(parseInt(e.get("month") + 1));
                      setendmonth(
                        e.get("year") === dayjs().year()
                          ? parseInt(dayjs().month() + 1)
                          : 12
                      );
                      setstartYear(e.get("year"));
                      // if (selected) {
                      // setstartdate(
                      //   e.get("year") +
                      //     "-" +
                      //     (parseInt(e.get("month") + 1) < 10
                      //       ? "0" + parseInt(e.get("month") + 1)
                      //       : parseInt(e.get("month") + 1)) +
                      //     "-" +
                      //     e.get("date")
                      // );
                      // setLoading(true);
                      // }
                    }}
                  />
                </div>
                <div ref={ref2}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faCalendar} />
                    &nbsp;&nbsp;&nbsp;&nbsp;End Date
                  </p>

                  <DatePicker
                    picker="month"
                    value={enddefaultDate}
                    disabledDate={(current) => {
                      const today = dayjs();
                      return (
                        (startYear &&
                          current &&
                          current.year() !== startYear) ||
                        (current && current > today.endOf("month"))
                      );
                    }}
                    format="MMM YYYY"
                    allowClear={false}
                    onChange={(e) => {
                      setrefresh(false);
                      setloadlayer(false);
                      setshowOpacity(false);
                      setshowGraph(false);

                      setendmonth(parseInt(e.get("month") + 1));
                      setstartYear(e.get("year"));

                      // Adjust the end date to match the new start year if necessary
                      let newEndDate = dayjs().year(e.get("year")).month(e.get("month")); // Set to December of the new start year
                      setenddefaultDate(newEndDate);
                      // if (selected) {
                      // setstartdate(
                      //   e.get("year") +
                      //     "-" +
                      //     (parseInt(e.get("month") + 1) < 10
                      //       ? "0" + parseInt(e.get("month") + 1)
                      //       : parseInt(e.get("month") + 1)) +
                      //     "-" +
                      //     e.get("date")
                      // );
                      // setLoading(true);
                      // }
                    }}
                  />
                </div>
                <div ref={ref3}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faCalculator} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Aggregation
                  </p>
                  <Select
                    showSearch
                    disabled={selected === "DrySpell" ? true : false}
                    placeholder="Select a calculation value"
                    optionFilterProp="children"
                    defaultValue={calcval}
                    options={[
                      // {
                      //   value: "default",
                      //   label: "Default",
                      // },
                      {
                        value: "mean",
                        label: "Mean",
                      },
                      {
                        value: "median",
                        label: "Median",
                      },
                      {
                        value: "min",
                        label: "Minimum",
                      },
                      {
                        value: "max",
                        label: "Maximum",
                      },
                    ]}
                    onChange={(e) => {
                      if (selected) {
                        setcalcval(e);
                        setloadlayer(false);
                        setshowOpacity(false);
                        setshowGraph(false);
                      }
                    }}
                  />
                </div>
                <div ref={ref4}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faGrip} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Module
                  </p>
                  <Select
                    showSearch
                    placeholder="Select a module"
                    optionFilterProp="children"
                    value={module}
                    options={[
                      // {
                      //   value: "prediction",
                      //   label: "Drought Prediction",
                      // },
                      {
                        value: "monitoring",
                        label: "Drought Monitoring",
                      },
                    ]}
                  />
                </div>
                <Space direction="vertical" ref={ref5}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faObjectGroup} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Region
                  </p>
                  {/* <Select
                  placeholder="Select a region"
                  optionFilterProp="children"
                  onChange={() => {
                    setselectedRegion("PAKISTAN");
                    setUnit("units");
                  }}
                  value={selectedRegion}
                  options={[
                    {
                      value: "PAKISTAN",
                      label: "Pakistan",
                    },
                  ]}
                /> */}
                  <Select
                    placeholder="Select a unit"
                    optionFilterProp="children"
                    onChange={(e) => {
                      setreset(true);
                      setloadlayer(false);
                      setshowOpacity(false);
                      setselectedUnit(e);
                      setselectedDistrict(null);
                      // selectedRegion("PAKISTAN");
                      setUnit("districts");
                    }}
                    value={admin1 ? admin1Name : selectedUnit}
                    options={[
                      {
                        value: "AZAD KASHMIR",
                        label: "AZAD KASHMIR",
                        disabled: admin1 && admin1Name !== "AZAD KASHMIR",
                      },
                      {
                        value: "BALOCHISTAN",
                        label: "BALOCHISTAN",
                        disabled: admin1 && admin1Name !== "BALOCHISTAN",
                      },
                      {
                        value: "GILGIT BALTISTAN",
                        label: "GILGIT BALTISTAN",
                        disabled: admin1 && admin1Name !== "GILGIT BALTISTAN",
                      },
                      {
                        value: "FEDERAL CAPITAL TERRITORY",
                        label: "FEDERAL CAPITAL TERRITORY",
                        disabled:
                          admin1 && admin1Name !== "FEDERAL CAPITAL TERRITORY",
                      },
                      {
                        value: "KHYBER PAKHTUNKHWA",
                        label: "KHYBER PAKHTUNKHWA",
                        disabled: admin1 && admin1Name !== "KHYBER PAKHTUNKHWA",
                      },
                      {
                        value: "PUNJAB",
                        label: "PUNJAB",
                        disabled: admin1 && admin1Name !== "PUNJAB",
                      },
                      {
                        value: "SINDH",
                        label: "SINDH",
                        disabled: admin1 && admin1Name !== "SINDH",
                      },
                    ]}
                  />
                  {districts.length > 0 || (admin1 && admin2) ? (
                    <>
                      <Select
                        placeholder="Select a district"
                        // onChange={() => {
                        //   setselectedRegion("PAKISTAN");
                        //   setUnit("units");
                        // }}
                        onChange={(e) => {
                          setreset(true);
                          setloadlayer(false);
                          setshowOpacity(false);
                          setselectedDistrict(e);
                          // selectedRegion("PAKISTAN");
                          setUnit("tehsils");
                        }}
                        disabled={admin2 ? true : false}
                        value={admin2 ? admin2Name : selectedDistrict}
                        options={districts}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {tehsils.length > 0 ? (
                    <>
                      <Select
                        placeholder="Select a tehsil"
                        // onChange={() => {
                        //   setselectedRegion("PAKISTAN");
                        //   setUnit("units");
                        // }}
                        onChange={(e) => {
                          setreset(true);
                          setloadlayer(false);
                          setshowOpacity(false);
                          setselectedTehsil(e);
                          // selectedRegion("PAKISTAN");
                          setUnit("subtehsil");
                        }}
                        value={selectedTehsil}
                        options={tehsils}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {reset ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        setselectedTehsil(null);
                        setUnit("units");
                        setselectedUnit(null);
                        setDistricts([]);
                        setTehsils([]);
                        setselectedDistrict(null);
                        setselectedTehsil(null);
                        setreset(false);
                        setlegend(false);
                        setloadlayer(false);
                        setshowGraph(false);
                        setshowOpacity(false);
                        dispatch(setSelected(null));
                        setshowprecipitation(false);
                        setshowmonths(false);
                      }}
                    >
                      Reset
                    </Button>
                  ) : (
                    ""
                  )}
                </Space>
                <div ref={ref6}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faObjectGroup} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Indicators
                  </p>
                  <Collapse bordered={false} accordion>
                    <Panel
                      header={
                        <Flex
                          justify="space-between"
                          style={{
                            color: selected
                              ? darkmode
                                ? "#1668dc"
                                : "#1668dc"
                              : "",
                          }}
                        >
                          <div className="column" style={{ width: "100%" }}>
                            <span>Indices</span>
                          </div>
                          <div className="column third-column">
                            <FontAwesomeIcon icon={faListCheck} />
                          </div>
                        </Flex>
                      }
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                          <>
                            <List.Item
                              style={{
                                pointerEvents: item.disabled ? "none" : "",
                                opacity: item.disabled ? 0.5 : 1,
                                backgroundColor:
                                  selected === data[index].title
                                    ? darkmode
                                      ? "#111a2c"
                                      : "#e6f4ff"
                                    : "",
                                color:
                                  selected === data[index].title
                                    ? darkmode
                                      ? "#1668dc"
                                      : "#1668dc"
                                    : null,
                              }}
                            >
                              <div className="container">
                                <div id="listItems">
                                  <div
                                    className="column first-column"
                                    onClick={() =>
                                      handleIndice(data[index].title)
                                    }
                                  >
                                    {selected === data[index].title ? (
                                      <FontAwesomeIcon icon={faEye} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    )}
                                  </div>
                                  <div
                                    className="column second-column"
                                    onClick={() =>
                                      handleIndice(data[index].title)
                                    }
                                  >
                                    {item.title}
                                  </div>
                                  <div className="column third-column">
                                    {data[index].detail ? (
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          setmodalMessage(
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: data[index].detail,
                                              }}
                                            />
                                          );
                                          setmodalIndice(data[index].title);
                                          setmodalopen(true);
                                        }}
                                        icon={faCircleQuestion}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </List.Item>
                          </>
                        )}
                      />
                    </Panel>
                  </Collapse>
                </div>
                {/* <Menu
                  // defaultSelectedKeys={["1"]}
                  selectedKeys={selected}
                  mode="inline"
                  items={[
                    getItem("Indices", "indices", <AppstoreOutlined />, [
                      // getItem("Drought", "drought", null, [
                      getItem(
                        "CWD",
                        "drought-monitor/CWD",
                        selected === "drought-monitor/CWD" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                      {
                        key: "CWD",
                        label: (
                          <Flex justify="space-between">
                            {selected === "CWD" ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                            <span>CWD</span>
                            <InfoCircleOutlined
                              onClick={() => {
                                // setinfoclicked(true);
                                setmodalopen(true);
                              }}
                            />
                          </Flex>
                        ),
                        style: { paddingInline: 0 },
                      },
                      getItem(
                        "MAI",
                        "drought-monitor/MAI",
                        selected === "drought-monitor/MAI" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                      getItem(
                        "NDVI",
                        "agriculture/NDVI",
                        selected === "agriculture/NDVI" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                      getItem(
                        "SMCI",
                        "drought/SMCI",
                        selected === "drought/SMCI" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                      getItem(
                        "TCI",
                        "drought-monitor/TCI",
                        selected === "drought-monitor/TCI" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                      getItem(
                        "VCI",
                        "agriculture/VCI",
                        selected === "agriculture/VCI" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                      getItem(
                        "VHI",
                        "drought-monitor/VHI",
                        selected === "drought-monitor/VHI" ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      ),
                    ]),
                  ]}
                  onClick={(e) => {
                    setloadlayer(false);
                    if (e.key === selected) {
                      setshowprecipitation(false);
                      setapplyBtn(true);
                      setgraphBtn(false);
                      setlegend(false);
                      dispatch(setSelected(null));
                    } else {
                      if (
                        e.key === "flood/extreme_rainfall" ||
                        e.key === "drought/dryspell"
                      ) {
                        setshowprecipitation(true);
                        openNotificationWithIcon(
                          "info",
                          "Adjust precipitation threshold value",
                          "",
                          "bottomRight"
                        );
                      }
                      setlegend(false);
                      setgraphBtn(true);
                      setapplyBtn(false);
                      dispatch(setSelected(e.key));
                    }
                  }}
                /> */}
                {showprecipitation ? (
                  <>
                    <p className="sidebar-module">
                      Precipitation Threshold (mm)
                    </p>
                    <InputNumber
                      min={1}
                      max={50}
                      step={0.5}
                      defaultValue={2.5}
                      onChange={(e) => {
                        setprecipitation(e);
                        setloadlayer(false);
                        setshowOpacity(false);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                {showmonths ? (
                  <>
                    <p className="sidebar-module">Months</p>
                    <Select
                      placeholder="Select month"
                      onChange={(e) => {
                        setmonths(e);
                        setloadlayer(false);
                        setshowOpacity(false);
                      }}
                      value={months}
                      options={[
                        {
                          value: "1",
                          label: "1",
                        },
                        {
                          value: "3",
                          label: "3",
                        },
                        {
                          value: "6",
                          label: "6",
                        },
                        {
                          value: "12",
                          label: "12",
                        },
                      ]}
                    />
                  </>
                ) : (
                  ""
                )}

                {/* <Space direction="horizontal">
                  <InputNumber defaultValue={0} min={-50} max={50} />
                  <InputNumber defaultValue={0} min={-50} max={50} />
                </Space> */}
                <Space ref={ref7} direction="horizontal" size="small">
                  <Button
                    type="primary"
                    disabled={applyBtn}
                    onClick={() => {
                      if (!selectedUnit) {
                        openNotificationWithIcon(
                          "info",
                          "Please select an administrative unit",
                          "",
                          "bottomRight"
                        );
                        return;
                      }
                      setloadlayer(true);
                      setshowOpacity(true);
                      setshowGraph(false);
                      setLoading(true);
                      setlegend(false);
                      setlayermin(null);
                      setlayermax(null);
                      setlayerPalette(null);
                      setrefresh(true);
                      setcalctype("map");
                    }}
                    icon={<FontAwesomeIcon icon={faLayerGroup} />}
                  >
                    Apply
                  </Button>
                  <Button
                    disabled={!graphBtn}
                    onClick={() => {
                      if (!selectedUnit) {
                        openNotificationWithIcon(
                          "info",
                          "Please select an administrative unit",
                          "",
                          "bottomRight"
                        );
                        return;
                      }
                      setcalctype("table");
                      setshowGraph(true);
                      setLoading(true);
                      setrefresh(true);
                      setlegend(false);
                      setloadlayer(false);
                      setshowOpacity(false);
                    }}
                    icon={<FontAwesomeIcon icon={faChartLine} />}
                  >
                    Graph
                  </Button>
                </Space>
              </Space>
            </Sider>
            {/* <Sidebar collapsed={collapsed} onChange={onChange} /> */}
            <Content ref={ref0} style={contentStyle}>
              <MapContainer
                center={center}
                zoom={zoom}
                className="map-container"
                maxZoom={27}
                loadingControl={true}
              >
                <AddMask />
                <Control prepend position="topleft">
                  <Button
                    icon={
                      collapsed ? (
                        <FontAwesomeIcon icon={faAnglesRight} />
                      ) : (
                        <FontAwesomeIcon icon={faAnglesLeft} />
                      )
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                    }}
                  />
                </Control>
                <LayersControl position="topright">
                  <LayersControl.BaseLayer
                    name="Google Satellite"
                    checked={false}
                  >
                    <LayerGroup name=" Google Satellite">
                      <TileLayer
                        maxZoom={27}
                        url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                      />
                      <TileLayer
                        maxZoom={27}
                        url="https://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}"
                      />
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer
                    name="Google Maps"
                    // checked={darkmode ? false : true}
                  >
                    <TileLayer
                      name="Google Maps"
                      maxZoom={27}
                      url="https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer
                    name="Carto Dark"
                    checked={!darkmode ? false : true}
                  >
                    <TileLayer
                      name="Carto Dark"
                      maxZoom={20}
                      url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="ESRI NatGeo" checked={false}>
                    <TileLayer
                      name="ESRI NatGeo"
                      maxZoom={16}
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer
                    name="ESRI World Imagery"
                    checked={darkmode ? false : true}
                  >
                    <TileLayer
                      name="ESRI World Imagery"
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Open Topomap" checked={false}>
                    <TileLayer
                      name="Open Topomap"
                      maxZoom={17}
                      url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay checked name="Earth Engine Layer">
                    {loadlayer ? (
                      <>
                        <EarthEngine
                          res={(val) => {
                            setshowOpacity(true);
                            setlayerPalette(val.palette);
                            setlayermax(val.max);
                            setlayermin(val.min);
                            setlegend(true);
                            selected === "DrySpell"
                              ? setgraphBtn(false)
                              : setgraphBtn(true);
                            setrefresh(false);
                            // setshowprecipitation(false);
                            // setshowmonths(false);
                          }}
                          error={(msg) => {
                            openNotificationWithIcon(
                              "error",
                              msg,
                              "",
                              "bottomRight"
                            );
                            setLoading(false);
                            setlegend(false);
                          }}
                          opac={opac}
                          loading={(e) => setLoading(e)}
                          precipitation={precipitation}
                          months={months}
                          calcval={calcval}
                          refresh={refresh}
                          startmonth={startmonth}
                          endmonth={endmonth}
                          startyear={startYear}
                          indicator={selected}
                          selectedUnit={selectedUnit}
                          selectedDistrict={selectedDistrict}
                          selectedTehsil={selectedTehsil}
                          calctype={calctype}
                        />
                        {legend && selected ? (
                          <>
                            <div
                              style={{
                                width: "auto",
                                background: !darkmode ? "white" : "black",
                                borderRadius: "5px",
                                padding: "5px",
                                position: "fixed",
                                bottom: "15px",
                                right: "15px",
                                zIndex: 5000,
                                color: !darkmode ? "black" : "white",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "18px" }}
                              >
                                {selected.toUpperCase()}
                              </span>
                              <br />

                              <span>{/* {startdate} to {enddate} */}</span>
                              <div
                                style={{
                                  width: "auto",
                                  display: "flex",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  zIndex: 5000,
                                  color: !darkmode ? "black" : "white",
                                }}
                              >
                                <span style={{ marginRight: "5px" }}>
                                  {layermin}
                                </span>
                                {layerPalette
                                  ? layerPalette.map((palette) => {
                                      return (
                                        <div
                                          style={{
                                            width: "10px",
                                            height: "15px",
                                            backgroundColor: "#" + palette,
                                          }}
                                          key={palette}
                                        />
                                      );
                                    })
                                  : ""}
                                <span style={{ marginLeft: "5px" }}>
                                  {layermax}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="Boundary">
                    {loggedin ? (
                      <VectorData
                        adminUnit={unit}
                        clickedUnit={clickedUnit}
                        selectedUnit={selectedUnit}
                        selectedDistrict={selectedDistrict}
                        selectedTehsil={selectedTehsil}
                        getdistricts={getdistricts}
                        gettehsils={gettehsils}
                      />
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="Surface Water">
                    {loggedin ? (
                      <JRCLayer
                        error={(msg) => {
                          openNotificationWithIcon(
                            "error",
                            msg,
                            "",
                            "bottomRight"
                          );
                          setLoading(false);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                </LayersControl>
                {/* <ResetMask reset={reset} /> */}
                {showGraph ? (
                  <div
                    style={{
                      zIndex: 5000,
                      overflow: "hidden",
                      height: "250px",
                    }}
                  >
                    <Drawer
                      mask={false}
                      placement="bottom"
                      // closable={false}
                      height={"auto"}
                      onClose={onClose}
                      open={!open}
                      getContainer={false}
                    >
                      <Graph
                        error={(msg) => {
                          openNotificationWithIcon(
                            "error",
                            msg,
                            "",
                            "bottomRight"
                          );
                          setLoading(false);
                          setshowGraph(false);
                        }}
                        loading={(e) => setLoading(e)}
                        precipitation={precipitation}
                        months={months}
                        calcval={calcval}
                        refresh={refresh}
                        startmonth={startmonth}
                        endmonth={endmonth}
                        startyear={startYear}
                        indicator={selected}
                        selectedUnit={selectedUnit}
                        selectedDistrict={selectedDistrict}
                        selectedTehsil={selectedTehsil}
                        calctype={calctype}
                      />
                    </Drawer>
                  </div>
                ) : (
                  ""
                )}
              </MapContainer>
              {showOpacity ? (
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                    bottom: "10em",
                    right: ".6em",
                    height: "10em",
                  }}
                >
                  <Slider
                    tooltip={(value) => `${value}%`}
                    marks
                    min={0}
                    max={1}
                    step={0.1}
                    vertical
                    value={opac}
                    onChange={(e) => sliderChange(e)}
                  />
                </div>
              ) : (
                ""
              )}
            </Content>
          </Layout>
        </Layout>
      </Spin>
      <Modal
        open={modalopen}
        title={modalIndice}
        // onOk={handleOk}
        onCancel={() => setmodalopen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => setmodalopen(false)}
          >
            Close
          </Button>,
        ]}
      >
        {modalMessage}
      </Modal>
      {showTour ? (
        <Tour
          open={showTour}
          onClose={() => {
            // setopenTour(false);
            dispatch(setshowTour(false));
          }}
          // onFinish={dispatch(setshowTour(false))}
          // onChange={dispatch(setshowTour(false))}
          steps={steps}
          mask={{
            style: {
              boxShadow: "inset 0 0 15px #333",
            },
            color: darkmode ? "rgba(255, 255, 255, .5)" : "rgba(0, 0, 0, .5)",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MapWrapper;
