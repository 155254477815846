import { Button, Space } from "antd";
import { setselectedKey } from "../slices/mapView";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faEnvelope,
  faRssSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faFlickr,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Footer(props) {
  const dispatch = useDispatch();
  return (
    <>
      <span className="Footer-icon-container">
        <div className="container">
          <div className="item-alignment row">
            <div className="col-md-6">
              <Space direction="horizontal">
                <a
                  href="https://www.iwmi.cgiar.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/iwmi.png"
                    className="img-fluid"
                    height="125"
                    width="125"
                    alt="iwmi"
                  />
                </a>
                <a href="https://cgiar.org/" target="_blank" rel="noreferrer">
                  <img
                    src="/images/cgiar-logo-white.png"
                    className="img-fluid"
                    width="50"
                    alt="CGIAR"
                  />
                </a>
                <a href="http://pmd.gov.pk/" target="_blank" rel="noreferrer">
                  <img
                    src="/images/Pakistan_Met_Department.png"
                    className="img-fluid"
                    width="70"
                    alt="PMD"
                  />
                </a>
                <a href="http://dms.iwmi.org/" target="_blank" rel="noreferrer">
                  <img
                    src="/images/UKaid.jpg"
                    className="img-fluid"
                    width="50"
                    alt="PAKDMS"
                  />
                </a>
              </Space>
            </div>
            <div className="fr-content ml-auto  col-12 col-sm-6 col-lg-4">
              <div className="home-footer-right">
                <div className="follow">
                  <p className="follow-text"></p>
                  <div className="follow-icon-wrapper d-flex">
                    <ul className="list-unstyled icon-ul footer-logos">
                      <li>
                        <Button
                          type="link"
                          href="https://www.facebook.com/IWMIonFB"
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faFacebookF}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                      <li>
                        <Button
                          type="link"
                          href="https://www.flickr.com/photos/iwmi"
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faFlickr}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                      <li>
                        <Button
                          type="link"
                          href="https://www.linkedin.com/company/international-water-management-institute-iwmi/"
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faLinkedin}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                      <li>
                        <Button
                          type="link"
                          href='mailto:iwmi@cgiar.org?subject="Contact IWMI via PakDMS"'
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                      <li>
                        <Button
                          type="link"
                          href="https://www.iwmi.cgiar.org/news/rss-feeds/"
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faRssSquare}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                      <li>
                        <Button
                          type="link"
                          href="https://twitter.com/IWMI_"
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faTwitter}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                      <li>
                        <Button
                          type="link"
                          href="https://www.youtube.com/user/iwmimedia/videos"
                          target="_blank"
                          icon={
                            <FontAwesomeIcon
                              icon={faYoutube}
                              color="white"
                              size="2x"
                            />
                          }
                        ></Button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="margin-contact">
                  <Button
                    href="/terms-conditions"
                    target="_blank"
                    className="footer-terms-style follow-text"
                    type="link"
                    onClick={() => dispatch(setselectedKey(null))}
                  >
                    Terms &amp; Conditions
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
      <span className="footer-social-meida-profils">
        <div className="container">
          <div className="row">
            <div className="fl-content col-21 col-md-6 col-lg-8">
              <div className="subscribe">
                <p className="subscribe-text">Subscribe to our newsletter</p>
                <form
                  action="mailto:iwmi@cgiar.org"
                  method="post"
                  encType="text/plain"
                >
                  <div className="input-group c-width">
                    <input
                      type="text"
                      className="form-control footer-input"
                      placeholder="Email Address"
                    />
                    <span className="input-group-btn pr">
                      <button className="btn btn-right">
                        <img
                          className=""
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACjSURBVHgBfZBNCsIwEIVnEq+gZyjNpidQjyBi3eo5xJ8I3kOXLjyErQfQVVy7K+QMZkxKhDakfZCQx/t4ZAbAKl1sD+5ARFzkMgMwV/ueDtMxaFUWLUCrezVKJh9AmMUg7i79Ll9dEP+TXRCGnxLz3ZoYnb2VLAS+A6SGxVZDstyvmDEXb4/qdpLYF9YVfWENiHyTEbFnLPSLelR2JNdUhKHTD0J/Tq3qzxWjAAAAAElFTkSuQmCC"
                          alt="right arrow"
                        />
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <p className="copyright-text copy-text-left follow-text">
            <FontAwesomeIcon icon={faCopyright} />
            &nbsp;2024 Pakistan Drought Monitoring System (PAKDMS)
          </p>
        </div>
      </span>
    </>
  );
}

export default Footer;
